import Experiments from '@wix/wix-experiments'
import {EditorSDK} from '@wix/platform-editor-sdk'
import {concurrentEditingEnabled} from '../experiments'
import {FlowApi} from '../types'

export type WithApproval = <T>(fn: () => T) => Promise<T>

export const createWithApproval = (
  editorSDK: EditorSDK,
  token: string,
  experiments: Experiments,
  adi: boolean,
  flowApi: FlowApi,
): WithApproval => {
  if (concurrentEditingEnabled(experiments) && !adi) {
    return async fn => {
      try {
        return await editorSDK.document.transactions.runAndWaitForApproval(token, fn)
      } catch (error) {
        const isTransactionError = await editorSDK.document.transactions.isConflictError(token, error)
        if (isTransactionError) {
          try {
            return await editorSDK.document.transactions.runAndWaitForApproval(token, fn)
          } catch (secondError) {
            flowApi.reportError(secondError)
          }
        } else {
          flowApi.reportError(error)
        }
        return null
      }
    }
  } else {
    return async fn => fn()
  }
}
